import { ToolAction } from "../actions/tool-action";

const initialState = {
    tool_data: {}
};


export const ToolReducerState = (state = initialState, action) => {
    switch (action.type) {
        case ToolAction.SET_TOOL_DATA:
            console.log("here",action.payload)
            return Object.assign({}, state, { tool_data: action.payload.tool_data });
        default:
            return state;
    }
}