import React, { useEffect, useState } from "react";
import Login from "./modules/Components/Login/Login.jsx";
import Navigation from "./modules/Navigation/Navigation.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SamlLogin from "./Saml-components/SamlLogin";
import Update from "./Saml-components/Update";
import MyFavourites from "./modules/Components/MyFavourites/MyFavourites.jsx";
import ExclusiveDashboard from "./modules/Components/ExclusiveDashboard/ExclusiveDashboard.jsx";
import ToolsHomePage from "./modules/Components/ToolsHomePage/ToolsHomePage.jsx";
import { CONFIG } from "./config/config.jsx";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getCookie } from "./utils/getCookie";
import { getUserIp } from "./utils/dataServices";
import BeagleFabModalComponent from "./modules/Components/BeagleFabModalComponent.jsx";

function App() {
  const [user, setUser] = useState("");
  const [userIP, setUserIP] = useState("");
  const { pushInstruction } = useMatomo();
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [iframeLink, setIframeLink] = useState("");
  const [isLoading, setIsLoading] = useState(0);

  // useEffect(() => {
  //   getUserIp().then((ip) => {
  //     setUserIP(ip["IPv4"]);
  //     setUser(getCookie("hubble_username") + `_IP:${userIP}`);
  //     console.log("user_ip", userIP);
  //   });
  //   pushInstruction("setUserId", user);
  // }, [user]);

  useEffect(() => {
    //  window.clarity("identify", getCookie("hubble_username"))
    window.clarity("set", "user_email", getCookie("hubble_username"));
    window.clarity("set", "userId", getCookie("hubble_username"));
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login></Login>}></Route>
          <Route
            path="/navigation/"
            element={
              <Navigation
                iframeLink={iframeLink}
                setIframeModalOpen={setIframeModalOpen}
                iframeModalOpen={iframeModalOpen}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setIframeLink={setIframeLink}
              ></Navigation>
            }
          >
            <Route path="my-favourites" element={<MyFavourites />}></Route>
            <Route
              path="executive-dashboard"
              element={<ExclusiveDashboard />}
            ></Route>
            <Route
              path="tools/post-event-promo-diagnosis-dashboards"
              element={
                <>
                  <ToolsHomePage
                    iframeModalOpen={iframeModalOpen}
                    setIframeModalOpen={setIframeModalOpen}
                    data={CONFIG.TOOL_HOME_PAGE_DATA[0]}
                    iframeLink={iframeLink}
                    setIframeLink={setIframeLink}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                  {iframeModalOpen? (
                    <BeagleFabModalComponent
                    ></BeagleFabModalComponent>
                  ) : (
                    ""
                  )}
                </>
              }
            ></Route>
            <Route
              path="tools/promo-what-if"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[1]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/pricing-dashboard"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[2]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/pvp"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[3]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/ammps"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[4]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/assortment-prioritization"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[5]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/aos"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[6]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/asset-planogram-tool"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[7]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/activation-analytics"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[8]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/predictive-order"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[9]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/fable"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[10]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/Asset-placement-simulator"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[11]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/integrated-commercial-planner"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[12]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/target-group-builder"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[13]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/demand-planning"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[14]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/pos-initiative"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[15]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/market-share-tool"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[16]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/simulator-chatbot"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[17]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/promotion-optimizer"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[18]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/assortment-and-mix"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[19]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
            <Route
              path="tools/command-center"
              element={
                <ToolsHomePage
                  iframeModalOpen={iframeModalOpen}
                  setIframeModalOpen={setIframeModalOpen}
                  data={CONFIG.TOOL_HOME_PAGE_DATA[20]}
                  iframeLink={iframeLink}
                  setIframeLink={setIframeLink}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
          </Route>
          <Route path="/saml-login" element={<SamlLogin></SamlLogin>}></Route>
          <Route path="/update" element={<Update></Update>}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
