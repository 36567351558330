import { combineReducers, createStore } from "redux";
import { ToolReducerState } from "./reducers/tool-reducer"; 

export default function configureStore() {
  const rootReducer = combineReducers({
   ToolReducerState
  });
  return createStore(
    rootReducer
  );
}