export const getCookie = (name) => {
  console.log(document.cookie)
    const ca = decodeURIComponent(document.cookie).split(";");
    const caLen = ca.length;
    const cookieName = `${name}=`;
    let c;
  
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      console.log("c",c)
      if (c.indexOf(cookieName) === 0) {
        console.log("sub",c.substring(cookieName.length, c.length))
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  };


  export const deleteEmptyHubbleAccessTokenCookie = () => {
    const ca = decodeURIComponent(document.cookie).split(";");
    for (let i = 0; i < ca.length; i += 1) {
      const cookie = ca[i].replace(/^\s+/g, "");
      const [name, value] = cookie.split("=");
      if (name === "hubble_access_token" && value === "") {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        break; 
      }
    }
  };
  