import React,{useEffect} from "react"
import { Typography, Box, CircularProgress } from "@mui/material"

const Login = () => {
  useEffect(() => {
      login()
      //navigate("/navigation/my-favourites")
  }, [])

  const login = () => {
    let saml_login = process.env.REACT_APP_SAML_ENDPOINT
    window.location.href = saml_login + "/authenticate"
    console.log("moved to login")
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
      <Box mt={2}>
        <Typography variant="h6" component="div">
          Authentication in progress...
        </Typography>
      </Box>
    </Box>
  )
}

export default Login