import React from 'react'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import GridViewIcon from '@mui/icons-material/GridView';


const TOOLS_LIST = [
  {
    "icon": <BookmarksOutlinedIcon/>,
    "title": "My Favourites",
     "to":"/my-favourites",
     "header":"Menu"
  },
  {
    "icon": <GridViewIcon/>,
    "title": "Executive Dashboard",
     "to":"/executive-dashboard",
     divider:true
  },
  {
    "icon": <TableRowsOutlinedIcon/>,
    "title": "Promotional Analytics",
    "header":"Tools by category",
    "items": [{
        "title": "Promo Diagnostics Tool",
        "to": "/post-event-promo-diagnosis-dashboards"
      },
      {
        "title": "Promotion Optimizer",
        "to": "/promotion-optimizer"
      },
      {
        "title": "Promo What-If Simulator",
        "to": "/promo-what-if"
      }
    ]
  },{
    "icon": <RocketLaunchOutlinedIcon/>,
    "title": "Price Management",
    "items": [{
        "title": "Pricing Analytics & Purchase Structure",
        "to": "/pricing-dashboard"
      },{
        "title": "Price-Volume-Profit Simulator",
        "to": "/pvp"
      }]
  },{
    "icon": <SyncAltOutlinedIcon/>,
    "title": "Assortment Analytics",
    "items": [{
        "title": "Store RGM",
        "to": "/ammps"
      },{
        // "title": "Assortment And Mix",
        // "to":  process.env.REACT_APP_MIX_MANAGEMENT,
        // "direct_to_tool":"true"
        "title": "Assortment And Mix",
        "to":"/assortment-and-mix"
      },{
        "title": "Availability On-Shelf",
        "to": "/aos"
      },{
        "title": "Asset Planogram Tool",
        "to": "/asset-planogram-tool"
      },{
        "title": "Activation Analytics",
        "to": "/activation-analytics"
      },{
        "title": "Predictive Order",
        "to": "/predictive-order"
      }]
  },{
    "icon": <DashboardOutlinedIcon/>,
    "title": "Trade Management",
    "items": [{
        "title": "FABLE",
        "to": "/fable"
      },{
        "title": "Asset Placement Simulator",
        "to": "/Asset-placement-simulator"
      }]
  },{
    "icon": <LayersOutlinedIcon/>,
    "title": "Integrated Tools",
    "items": [
      {
        "title": "Integrated Business Planner",
        "to": "/integrated-commercial-planner"
      },
      {
        "title": "Target Group Builder",
        "to": "/target-group-builder"
      },{
        "title": "Demand Planning",
        "to": "/demand-planning"
      },{
        "title": "Shopper Marketing",
        "to": "/pos-initiative"
      },{
        "title": "Growth Decomposition Tool",
        "to": "/market-share-tool"
      },{
        "title": "Beagle",
        "to": "/simulator-chatbot"
      }]
  },
  {
    "icon": <BuildOutlinedIcon style={{transform: 'rotate(90deg)'}}/>,
    "title": "All Tools",
    "items": [
      {
        "title": "Promo Diagnostics Tool",
        "to": "/post-event-promo-diagnosis-dashboards"
      },{
        "title": "Promo What-If Simulator",
        "to": "/promo-what-if"
      },{
        "title": "Pricing Analytics & Purchase Structure",
        "to": "/pricing-dashboard"
      },{
        "title": "Price-Volume-Profit Simulator",
        "to": "/pvp"
      },{
        "title": "Store RGM",
        "to": "/ammps"
      },{
        "title": "Assortment And Mix",
        // "to": process.env.REACT_APP_MIX_MANAGEMENT,
        // "direct_to_tool":"true"
        "to":"/assortment-and-mix"
      },{
        "title": "Availability On-Shelf",
        "to": "/aos"
      },{
        "title": "Asset Planogram Tool",
        "to": "/asset-planogram-tool"
      },{
        "title": "Activation Analytics",
        "to": "/activation-analytics"
      },{
        "title": "Predictive Order",
        "to": "/predictive-order"
      },{
        "title": "FABLE",
        "to": "/fable"
      },{
        "title": "Asset Placement Simulator",
        "to": "/Asset-placement-simulator"
      },
      {
        "title": "Integrated Business Planner",
        "to": "/integrated-commercial-planner"
      },
      {
        "title": "Target Group Builder",
        "to": "/target-group-builder"
      },{
        "title": "Demand Planning",
        "to": "/demand-planning"
      },{
        "title": "Shopper Marketing",
        "to": "/pos-initiative"
      },{
        "title": "Growth Decomposition Tool",
        "to": "/market-share-tool"
      },{
        "title": "Beagle",
        "to": "/simulator-chatbot"
      },
      {
        "title": "Promotion Optimizer",
        "to": "/promotion-optimizer"
      },
      {
        "title": "Command Center",
        "to":"/command-center"
        // "to": "https://command-center.decisionpoint.in",
        // "direct_to_tool":"true"
      },
    ],
  },
  {
    "icon":<WidgetsIcon/>,
    "title": "Admin",
    "items": [{
      "title": "Command Center",
        "to":"/command-center"
        // "title": "Command Center Tool",
        // "to": "https://command-center.decisionpoint.in",
        // "direct_to_tool":"true"
      }],
      divider:true
  },
]
export default TOOLS_LIST