import React from 'react'

import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import TourIcon from '@mui/icons-material/Tour';
import HelpIcon from '@mui/icons-material/Help';

const Help_list = {

    "icon": <HelpIcon style={{marginRight:"10px"}} />,
    "title": "Help",
    "items": [{
        "title": "FAQ",
        "to": ""
      },{
        "title": "TOUR",
        "to": ""
      }]
}

export default Help_list;