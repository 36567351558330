import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import './Login.css'
import React, { useEffect } from 'react'
import { IMAGECONFIG } from '../../../config/image-config';
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/getCookie"
import { callApi } from "../../../utils/dataServices"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function Login() {
    const navigate = useNavigate()
 useEffect(()=>{
    console.log("REACT_APP_NODE_ENV",process.env.REACT_APP_NODE_ENV)
        callApi(getCookie("hubble_access_token")).then(res => {
          console.log("res",res)
          if(res['code']==200){
            //document.cookie="hubble_access_token=;path=/;domain=.decisionpoint.in;"
            navigate("/navigation/my-favourites");
          }
        }).catch(err => {
          console.log("error message", err.message)
          document.cookie="hubble_access_token=;path=/;domain=.decisionpoint.in;"
        })
 },[])
    return (
        <div className='login'>
            <div className='top'>
                <img src={IMAGECONFIG.HUBBLELOGO} style={{ maxWidth: "170px", margin: "20px 20px 0" }} />
                <Typography variant="h1" gutterBottom style={{ margin: "10px 20px 0", width: "50%", fontWeight: 700, fontSize: "50px", color: "#FFFFFF", fontFamily: 'Montserrat' }}>
                    Revolutionizing the approach towards RGM Initiatives
                </Typography>
                <Typography variant="body2" gutterBottom style={{ margin: "20px", width: "50%", fontWeight: 500, fontSize: "16px", color: "rgba(255, 255, 255, 0.6)", fontFamily: 'Montserrat' }}>
                    Consisting of ready to deploy, ready to integrate and ready to deliver market relevant tools right at your finger tips
                </Typography>
            </div>
            {/* <div className='bottom'>
                <Typography variant='h3' gutterBottom style={{ fontWeight: 400, fontSize: '25px', fontFamily: 'Montserrat' }}>
                    Login As
                </Typography>
                <Grid container spacing={1} style={{ marginTop: "10px" }}>
                    <Grid item xs={2}>
                        <Card className='login-card'>
                            <CardContent>
                                <div className='login-card-content'>
                                    <HighlightOffIcon style={{ alignSelf: "self-end" }} />
                                    <img src={IMAGECONFIG.SAMPLE_USER_1} style={{ margin: "25px auto" }} />
                                    <Typography variant='h6' gutterBottom style={{ fontWeight: 600, fontSize: '15px', fontFamily: 'Montserrat', textAlign: "center" }}>
                                        John peter
                                    </Typography>
                                    <Typography variant='caption' gutterBottom style={{ fontWeight: 300, fontSize: '12px', fontFamily: 'Montserrat', color: "#595959", textAlign: "center" }}>
                                        Active 1 days ago
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div> */}
            <div className='login-form'>
                <Typography variant='h5' style={{ fontWeight: 600, fontSize: "30px", color: "#101828", fontFamily: 'Montserrat' }}>
                    Powerful, self-serve product and growth analytics
                </Typography>
                <iframe
                    style={{ margin: "10px auto", border: "0px" }}
                    src="https://www.youtube.com/embed/EquLbxiJ_7s"
                    allowfullscreen="allowfullscreen"
                    width="100%"
                    height="250"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
                <div className='buttons' style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "10px" }}>
                    <Button style={{
                        background: "#00A5A5",
                        boxShadow: "0px 4px 19px rgba(0, 165, 165, 0.3)",
                        borderRadius: "10px",
                        fontFamily: 'Montserrat',
                        fontSize: "18px",
                        color: "white",
                        textTransform: "capitalize",
                        width: "100%",
                    }} variant="contained" size="large" onClick={() => navigate("/saml-login")} disableElevation>
                        Login
                    </Button>
                   </div>
                <Typography variant="subtitle2" style={{ textAlign: "center", color: "#667085" }}>
                    
                </Typography>
                <div className='trusted-clients' style={{ display: "flex", justifyContent: "space-around", marginTop: "10px", alignItems: "center",flexWrap:"wrap" }}>
                    <img src={IMAGECONFIG.TRUSTED_CLIENT_1} style={{ width: "100px", height: "30px" }} />
                    <img src={IMAGECONFIG.TRUSTED_CLIENT_2} style={{ width: "90px", height: "35px" }} />
                    <img src={IMAGECONFIG.TRUSTED_CLIENT_3} style={{ width: "70px", height: "40px" }} />
                    <img src={IMAGECONFIG.TRUSTED_CLIENT_4} style={{ width: "100px", height: "60px" }} />
                </div>
            </div>
        </div>
    )
}

export default Login