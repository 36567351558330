export const postData = async (data) => {
  const baseUrl = process.env.REACT_APP_HUBBLE_BACKEND_URL+"/saveToken"
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  }
  return fetch(baseUrl, options)
}

export const callApi = async (data) => {
  const baseUrl = process.env.REACT_APP_HUBBLE_BACKEND_URL+"/usersDetails"
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'token': data ||0
    },
  }
  let response=await fetch(baseUrl, options)
  let res=await response.json()
  return res
}

export const getUserIp = async () => {
  const baseUrl = "https://geolocation-db.com/json/"
  let options = {
    method: 'GET',
  }
  let response=await fetch(baseUrl)
  let res=await response.json()
  console.log("res=======>",res)
  return res
}

