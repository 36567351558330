import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import './Tools.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CONFIG } from '../../../config/config'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { useState } from 'react';
import { Icon, Input } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';
import { IMAGECONFIG } from '../../../config/image-config';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        padding: 0
    }
}));

function Tools() {
    const navigate = useNavigate()
    const classes = useStyles();
    const [activeSection, setActiveSection] = useState(1);
    const [activeSegment, setActiveSegment] = useState(0);
    const [searchValue, setSearchValue] = useState('')
    const [viewType, setViewType] = useState('Mosiac')
    const [value, setValue] = useState(0);
    const moveBehind = () => {
        value === -100 * (5 - 2)
            ? setValue(0)
            : setValue(value - 100);
    };
    const moveAhead = () => {
        value === 0
            ? setValue(-100 * (5 - 2))
            : setValue(value + 100);
    };

    const updateActiveSection = (sectionIndex) => {
        setActiveSegment(0)

        if (sectionIndex === activeSection) {
            setActiveSection(-1)
        } else {
            setActiveSection(sectionIndex)
            if (sectionIndex === 0 && viewType !== 'List') {
                setValue(0);
                setViewType('List');
            } else {
                if (sectionIndex === 1 && activeSegment === 0 && viewType !== 'Mosiac') {
                    setValue(0);
                    setViewType('Mosiac');
                }
            }
        }
    }

    const setToolDataAndNavigate = (project_link,direct_link) => {
        if(direct_link ) window.open(project_link)
        else navigate(`/navigation/tools/${project_link}`)
    }

    const updateSegmentAndView = (segment) => {
        if (viewType !== 'List') {
            setViewType('List')
        } else if (activeSection === 1 && segment === 0 && viewType !== 'Mosiac') {
            setValue(0);
            setViewType('Mosiac');
        }
        setActiveSegment(segment)
    }

    const getFilteredTools = (section) => {
        let filtered_tools = section.segemts[activeSegment] && section.segemts[activeSegment].apps && section.segemts[activeSegment].apps.filter((card) => (card.name.toLowerCase().trim()).includes(searchValue.toLowerCase().trim()));
        return filtered_tools
    }

    const handleSearchInput = (value) => {
        if (viewType !== 'List') {
            setViewType('List')
        }
        setSearchValue(value)
    }



    return (
        <div className='tools'>
            {CONFIG.TOOL_DATA.map((section, i) => {
                return <div className='sections' key={i}>
                    <Accordion className='component review-tool' expanded={activeSection === i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon onClick={() => updateActiveSection(i)} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography variant="h5" style={{ fontWeight: 600, fontSize: '20px', fontFamily: 'Montserrat' }}>
                              {section.section}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption" display="block" gutterBottom style={{ color: 'rgba(89, 89, 89, 0.8)', fontFamily: 'Montserrat' }}>
                                Discover our tools on the go using our suite designed to cater to your needs.
                            </Typography>
                            <div>
                                <List className={classes.root}>
                                    {section.segemts.length > 0 && section.segemts.map((seg, j) => {
                                        let name = seg.segment.includes(" ") ? seg.segment.split(" ").join("\n") : seg.segment
                                        console.log(name)
                                        return <ListItem onClick={() => updateSegmentAndView(j)} className={activeSegment === j ? 'active-list-item list-item' : 'list-item'}>
                                            <ListItemText primary={`${name} (${seg.apps.length})`} style={{ fontFamily: 'Montserrat', fontSize: "10.5px" }} />
                                        </ListItem>
                                    })}
                                </List>
                                <div className='search-view-bar'>
                                    {activeSection === 1 ? <Input
                                        className="inputSearch"
                                        placeholder="Search for tools"
                                        icon={<Icon name='search' />}
                                        iconPosition='left'
                                        value={searchValue}
                                        onChange={(e) => {
                                            handleSearchInput(e.target.value);
                                        }}
                                    /> : null}
                                    {section.segemts[activeSegment] && section.segemts[activeSegment].segment === 'All Tools' ?
                                        <div className='view-tab'>
                                            <div className={viewType === 'List' ? 'view-icon active-icon' : 'view-icon'}>
                                                <Icon name='bars' size='large' style={{ margin: 0, color: "#344054", opacity: 0.8 }} onClick={() => setViewType('List')} />
                                            </div>
                                            <div className={viewType === 'Mosiac' ? 'view-icon active-icon' : 'view-icon'}>
                                                <Icon name='grid layout' style={{ margin: 0, color: "#344054", opacity: 0.8 }} size='large' onClick={() => {
                                                    setValue(0);
                                                    setViewType('Mosiac');
                                                }} />
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                {viewType === 'List' ?
                                    <Grid container spacing={1} style={{ marginTop: "10px" }}>
                                        {getFilteredTools(section) ? getFilteredTools(section).map((card, k) => {
                                            return <Grid key={k} item xs={3}>
                                                <Card className='card-tool'>
                                                    <CardContent className='card-content'>
                                                        <div className='top-bottom-card-content'>
                                                            <div className='top-card-content'>
                                                                <div className='logo-and-name'>
                                                                    <div className="promo-logo" style={{ background: CONFIG.TOOL_ICON_BACKGROUND[k] }}>
                                                                        {card.logoLink ?
                                                                            <img
                                                                                src={card.logoLink}
                                                                                alt={card.name}
                                                                                className="promo-icons"
                                                                            /> : ""
                                                                        }
                                                                    </div>
                                                                    <h6 onClick={() => setToolDataAndNavigate(card.projectLink,card.direct_link||false)} className="promo-name" style={{ cursor: "pointer" }}>{card.name}</h6>
                                                                </div>
                                                                <p className="promo-oneLiner">{card.description}</p>
                                                            </div>
                                                            <div className='top-card-content'>
                                                                <div className='update-and-users'>
                                                                    <Typography variant='caption' className='text-val' gutterBottom display="block" style={{ color: '#595959' }}>
                                                                        Updated 1wk ago
                                                                    </Typography>
                                                                    <div className='users'>
                                                                        <PeopleOutlineOutlinedIcon style={{ fontSize: "1.3em" }} />
                                                                        <Typography variant='caption' className='text-val' gutterBottom display="block" style={{ color: '#595959', marginLeft: "5px" }}>
                                                                            22
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        }) : null}
                                    </Grid> :
                                    <div className='mosiac'>
                                        <div className='mosiac-glider'>
                                            <div
                                                className="mosiac-glide"
                                                style={{ transform: `translateX(${value}%)` }}>
                                                <div className='mosiac-flex'>
                                                    <div className='center-text'>
                                                        <Typography variant="h2" gutterBottom style={{ fontWeight: 600, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            One platform for all business solutions
                                                        </Typography>
                                                    </div>
                                                    <div className='mosiac-tools'>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "left" }}>
                                                                <Typography
                                                                    onClick={() => updateSegmentAndView(2)} variant="h5" style={{ fontWeight: 600, color: '#04A596', fontFamily: 'Montserrat' }}>
                                                                    Pricing
                                                                </Typography>
                                                                <Typography variant="caption" gutterBottom style={{ fontWeight: 400, color: '#191919', fontFamily: 'Montserrat' }}>
                                                                    Simulate PVP scenarios and tap key pricing opportunities
                                                                </Typography>
                                                            </div>
                                                            <div style={{ maxWidth: "40%", textAlign: "right" }}>
                                                                <Typography
                                                                    onClick={() => updateSegmentAndView(1)} variant="h5" style={{ fontWeight: 600, color: '#188FAD', fontFamily: 'Montserrat' }}>
                                                                    Promotion
                                                                </Typography>
                                                                <Typography variant="caption" gutterBottom style={{ fontWeight: 400, color: '#191919', fontFamily: 'Montserrat' }}>
                                                                    Evaluate, Plan and Optimize Promotional activities
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className='first-row' style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
                                                            <div className='mosiac-tool'
                                                                onClick={() => updateSegmentAndView(2)}>
                                                                <div className='mosiac-card right-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PRICINGVECTOR}
                                                                        alt={IMAGECONFIG.PRICINGVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='mosiac-tool'
                                                                onClick={() => updateSegmentAndView(1)}>
                                                                <div className='mosiac-card left-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        alt={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginLeft: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='second-row' style={{ display: "flex", justifyContent: "space-around", marginTop: "-100px", position: "relative", zIndex: "1" }}>
                                                            <div className='mosiac-tool'
                                                                onClick={() => updateSegmentAndView(5)}>
                                                                <div className='mosiac-card center'>
                                                                    <img
                                                                        src={IMAGECONFIG.INTEGERATEDVECTOR}
                                                                        alt={IMAGECONFIG.INTEGERATEDVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#04A596", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px" }}
                                                                    />
                                                                    <Typography variant="h5" gutterBottom style={{ fontWeight: 600, color: '#1C1C1C', fontFamily: 'Montserrat', marginLeft: "10px" }}>
                                                                        Integrated
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='third-row' style={{ display: "flex", justifyContent: "space-evenly", marginTop: "-80px" }}>
                                                            <div className='mosiac-tool'
                                                                onClick={() => updateSegmentAndView(4)}>

                                                                <div className='mosiac-card bottom-right-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.ASSORTMENTVECTOR}
                                                                        alt={IMAGECONFIG.ASSORTMENTVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginTop: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='mosiac-tool'
                                                                onClick={() => updateSegmentAndView(3)}>
                                                                <div className='mosiac-card bottom-left-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.TRADEVECTOR}
                                                                        alt={IMAGECONFIG.TRADEVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid #3F73AC", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginLeft: "140px", marginTop: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "left" }}>
                                                                <Typography
                                                                    onClick={() => updateSegmentAndView(4)}
                                                                    variant="h5" style={{ fontWeight: 600, color: "#5D5A9F", fontFamily: 'Montserrat' }}>
                                                                    Assortment
                                                                </Typography>
                                                                <Typography variant="caption" gutterBottom style={{ fontWeight: 400, color: '#191919', fontFamily: 'Montserrat' }}>
                                                                    Determine the optimal Product Assortment for your business segments
                                                                </Typography>
                                                            </div>
                                                            <div style={{ maxWidth: "40%", textAlign: "right" }}>
                                                                <Typography onClick={() => updateSegmentAndView(3)} variant="h5" style={{ fontWeight: 600, color: '#3F73AC', fontFamily: 'Montserrat' }}>
                                                                    Trade
                                                                </Typography>
                                                                <Typography variant="caption" gutterBottom style={{ fontWeight: 400, color: '#191919', fontFamily: 'Montserrat' }}>
                                                                    Help manufacturers manage and execute their trade operations.
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mosiac-glide"
                                                style={{ transform: `translateX(${value}%)` }}>
                                                <div className='mosiac-flex'>
                                                    <div className='center-text'>
                                                        <Typography variant="h2" gutterBottom style={{ fontWeight: 600, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            Increase pricing to manage
                                                            inflationary cost.
                                                        </Typography>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            A sweet spot can be identified by RGM suite
                                                            instead of price and promotion solution being
                                                            used in isolation.
                                                        </Typography>
                                                        <div className='all-tools'>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Market Share Tools
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price and Promo Personaliser
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price Diagnostics
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Post Event Analyser
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Simulation Chatbots
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price Volume Profit Simulator
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Promo Simulator & Planner
                                                            </Typography>

                                                        </div>
                                                    </div>
                                                    <div className='mosiac-tools'>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "left" }}>
                                                                <Typography onClick={() => updateSegmentAndView(2)} variant="h5" style={{ fontWeight: 600, color: '#04A596', fontFamily: 'Montserrat' }} >
                                                                    Pricing
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className='first-row' style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px", marginLeft: "40px" }}>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(2)}>

                                                                <div className='mosiac-card right-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PRICINGVECTOR}
                                                                        alt={IMAGECONFIG.PRICINGVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='third-row' style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", marginRight: "40px" }}>
                                                            <div className='mosiac-tool'
                                                                onClick={() => updateSegmentAndView(1)}>

                                                                <div className='mosiac-card bottom-left-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        alt={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginTop: "140px", marginLeft: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "right" }}>
                                                                <Typography onClick={() => updateSegmentAndView(1)} variant="h5" style={{ fontWeight: 600, color: '#188FAD', fontFamily: 'Montserrat' }}>
                                                                    Promotion
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mosiac-glide"
                                                style={{ transform: `translateX(${value}%)` }}>
                                                <div className='mosiac-flex'>
                                                    <div className='center-text'>
                                                        <Typography variant="h2" gutterBottom style={{ fontWeight: 600, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            Increase pricing
                                                            while minimizing
                                                            sales loss.
                                                        </Typography>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            Run promotions on substitute SKU groups in
                                                            stores and drive penetration of these product
                                                            groups through assortment optimization solutions.
                                                        </Typography>

                                                        <div className='all-tools'>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price Pack Architecture Study
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Opportunity Mapping tool
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Assortment Optimization tool
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Planogram tool
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Simulation Chatbots
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Promo Simulator & Planner
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className='mosiac-tools'>
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <div>
                                                                <Typography onClick={() => updateSegmentAndView(2)} variant="h5" style={{ fontWeight: 600, color: '#04A596', fontFamily: 'Montserrat' }}>
                                                                    Pricing
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className='first-row' style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(2)}>

                                                                <div className='mosiac-card right-corner' >
                                                                    <img
                                                                        src={IMAGECONFIG.PRICINGVECTOR}
                                                                        alt={IMAGECONFIG.PRICINGVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='third-row' style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(4)}>

                                                                <div className='mosiac-card bottom-right-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.ASSORTMENTVECTOR}
                                                                        alt={IMAGECONFIG.ASSORTMENTVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginTop: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(1)}>

                                                                <div className='mosiac-card bottom-left-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        alt={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginTop: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "left" }}>
                                                                <Typography onClick={() => updateSegmentAndView(4)} variant="h5" style={{ fontWeight: 600, color: '#188FAD', fontFamily: 'Montserrat' }}>
                                                                    Assortment
                                                                </Typography>
                                                            </div>
                                                            <div style={{ maxWidth: "40%", textAlign: "right" }}>
                                                                <Typography onClick={() => updateSegmentAndView(1)} variant="h5" style={{ fontWeight: 600, color: '#188FAD', fontFamily: 'Montserrat' }}>
                                                                    Promotion
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mosiac-glide"
                                                style={{ transform: `translateX(${value}%)` }}>
                                                <div className='mosiac-flex'>
                                                    <div className='center-text'>
                                                        <Typography variant="h2" gutterBottom style={{ fontWeight: 600, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            Holistic impact of RGM levers
                                                            in one platform.
                                                        </Typography>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                            Promotions, pricing and assortment under one roof
                                                            making it easy to execute at retail stores.

                                                        </Typography>
                                                        <div className='all-tools'>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price Volume Profit Simulator
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Market Share Tools
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price and Promo Personaliser
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Price Diagnostics
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Post Event Analyser
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Simulation Chatbots
                                                            </Typography>
                                                            <Typography variant="body1" className='tool-links' gutterBottom style={{ fontWeight: 400, color: '#131313', fontFamily: 'Montserrat' }}>
                                                                Promo Simulator & Planner
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className='mosiac-tools'>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "left" }}>
                                                                <Typography onClick={() => updateSegmentAndView(2)} variant="h5" style={{ fontWeight: 600, color: '#04A596', fontFamily: 'Montserrat' }}>
                                                                    Pricing
                                                                </Typography>
                                                            </div>
                                                            <div style={{ maxWidth: "40%", textAlign: "right" }}>
                                                                <Typography onClick={() => updateSegmentAndView(1)} variant="h5" style={{ fontWeight: 600, color: '#188FAD', fontFamily: 'Montserrat' }}>
                                                                    Promotion
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className='first-row' style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(2)}>

                                                                <div className='mosiac-card right-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PRICINGVECTOR}
                                                                        alt={IMAGECONFIG.PRICINGVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(1)}>
                                                                <div className='mosiac-card left-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        alt={IMAGECONFIG.PROMOTIONVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginLeft: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='second-row' style={{ display: "flex", justifyContent: "space-around", marginTop: "-100px", position: "relative", zIndex: "1" }}>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(5)}>
                                                                <div className='mosiac-card center'>
                                                                    <img
                                                                        src={IMAGECONFIG.INTEGERATEDVECTOR}
                                                                        alt={IMAGECONFIG.INTEGERATEDVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#04A596", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px" }}
                                                                    />
                                                                    <Typography variant="h5" gutterBottom style={{ fontWeight: 600, color: '#1C1C1C', fontFamily: 'Montserrat', marginLeft: "10px" }}>
                                                                        Integrated
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='third-row' style={{ display: "flex", justifyContent: "space-evenly", marginTop: "-80px" }}>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(4)}>

                                                                <div className='mosiac-card bottom-right-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.ASSORTMENTVECTOR}
                                                                        alt={IMAGECONFIG.ASSORTMENTVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid rgba(4, 165, 150, 0.46)", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginTop: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='mosiac-tool' onClick={() => updateSegmentAndView(3)}>
                                                                <div className='mosiac-card bottom-left-corner'>
                                                                    <img
                                                                        src={IMAGECONFIG.TRADEVECTOR}
                                                                        alt={IMAGECONFIG.TRADEVECTOR}
                                                                        className="promo-icons"
                                                                        style={{ background: "#FFFFFF", border: "0.997109px solid #3F73AC", borderRadius: "9.97109px", padding: "5px", minWidth: "40px", minHeight: "40px", marginLeft: "140px", marginTop: "140px" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                                            <div style={{ maxWidth: "40%", textAlign: "left" }}>
                                                                <Typography variant="h5" style={{ fontWeight: 600, color: "#5D5A9F", fontFamily: 'Montserrat' }}>
                                                                    Assortment
                                                                </Typography>
                                                            </div>
                                                            <div style={{ maxWidth: "40%", textAlign: "right" }}>
                                                                <Typography variant="h5" style={{ fontWeight: 600, color: '#3F73AC', fontFamily: 'Montserrat' }}>
                                                                    Trade
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "left" }} >
                                            <div className='prev-arrow' onClick={value === 0 ? null : moveAhead} style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                                                <Icon name='arrow left' size='large' style={{ cursor: "pointer", color: value === 0 ? "grey" : "black" }} />
                                                <Typography varient='caption' style={{ color: value === 0 ? "grey" : "black" }}>Prev</Typography>
                                            </div>
                                            <div className='prev-arrow' onClick={value === -300 ? null : moveBehind} style={{ marginLeft: "40px", display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                                                <Icon name='arrow right' size='large' style={{ color: value === -300 ? "grey" : "black" }} />
                                                <Typography varient='caption' style={{ color: value === -300 ? "grey" : "black" }}>Next</Typography>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            })}
        </div>
    )
}


export default Tools