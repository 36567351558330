import React, { useState } from "react";
import { Fab, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IMAGECONFIG } from "../../config/image-config";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

const BeagleFabModalComponent = () => {
  const [open, setOpen] = useState(false);
  const [isSmallWindow, setIsSmallWindow] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const resizeHandler = () => setIsSmallWindow(!isSmallWindow);

  const fabStyle = {
    position: "fixed",
    bottom: "40px",
    right: "102px",
    height: "0px",
    width: "0px",
  };

  const chatbotStyle = {
    position: "fixed",
    bottom: isSmallWindow ? "27px" : "25px",
    right: isSmallWindow ? "18px" : "10%",

    width: isSmallWindow ? "350px" : "80%",
    height: open ? "520px" : "0px",
    bgcolor: "background.paper",
    boxShadow: "0px 9.638px 79.514px 0px rgba(0, 0, 0, 0.25)",
    p: 2,
    borderRadius: "8px",
    overflow: "hidden",
    transition: "height 0.3s ease",
    display: open ? "block" : "none",
  };

  return (
    <div>
      {!open ? (
        <Fab color="primary" style={fabStyle} onClick={handleOpen}>
          {/* <AddIcon /> */}
          <img src={IMAGECONFIG.ASKBEAGLE} alt="" />
        </Fab>
      ) : (
        <Box sx={chatbotStyle}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
            paddingBottom="10px"
          >
            {/* {isSmallWindow ? (
              <IconButton onClick={resizeHandler}>
                <OpenInFullIcon sx={{ color: "#25D4CF" }} />
              </IconButton>
            ) : (
              <IconButton onClick={resizeHandler}>
                <CloseFullscreenIcon sx={{ color: "#25D4CF" }} />
              </IconButton>
            )} */}
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#25D4CF" }} />
            </IconButton>
          </Box>
          <iframe
            src={process.env.REACT_APP_BEAGLE_GPT}
            width="100%"
            height="480"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </Box>
      )}
    </div>
  );
};

export default BeagleFabModalComponent;
