import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { setToolData } from "../../../redux/actions/tool-action";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import "./ToolsHomePage.css";
import { IMAGECONFIG } from "../../../config/image-config";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CONFIG } from "../../../config/config";
import IframeModal from "../IframeModal/IframeModal";
import { Icon, Image } from "semantic-ui-react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

function ToolsHomePage({
  data,
  iframeModalOpen,
  setIframeModalOpen,
  iframeLink,
  setIframeLink,
  isLoading,
  setIsLoading
}) {
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState(0);
  // const [iframeModalOpen, setIframeModalOpen] = useState(false)
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);
  // const [iframeLink, setIframeLink] = useState('')
  const { trackEvent } = useMatomo();
  const iframeRef = useRef(null);
  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [iframeRef]);
  const moveBehind = () => {
    value === -100 * (data.properties.length - 1)
      ? setValue(0)
      : setValue(value - 100);
  };
  const moveAhead = () => {
    value === 0
      ? setValue(-100 * (data.properties.length - 1))
      : setValue(value + 100);
  };

  const handleToolClickEvent = (tool_link, tool_name) => {
    console.log("clicked tool");
    trackEvent({
      category: "tool",
      action: `clicked on ${tool_name}`,
      href: tool_link,
    });
  };

  useEffect(() => {
    setValue(0);
    dispatcher(setToolData(data));
    window.scrollTo(0, 0);
    setActiveTestimonialIndex(0);
    setIframeModalOpen(false);
    return () => {
      dispatcher(setToolData({}));
      window.scrollTo(0, 0);
      setActiveTestimonialIndex(0);
      setIframeModalOpen(false);
    };
  }, [data]);

  const setToolDataAndNavigate = (project_link) => {
    navigate(`/navigation/tools/${project_link}`);
  };

  const setIframeModalOpenAndSetLink = (link, tool_name) => {
    setIsLoading(true);
    setIframeLink(link);
    setIframeModalOpen(true);
    handleToolClickEvent(link, tool_name);
  };

  const navigateToTool = (tool_link, tool_name) => {
    if (tool_link !== "#") {
      window.open(`${tool_link}`);
      handleToolClickEvent(tool_link, tool_name);
    }
  };

  return (
    <div className="tools-homepage">
      {!iframeModalOpen ? (
        <div>
          <div className="top-profile">
            <div className="left">
              <Typography
                variant="h4"
                style={{ fontWeight: 600, fontFamily: "Montserrat" }}
              >
                {data.value_prop}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                style={{ fontFamily: "Montserrat", marginBottom: "30px" }}
              >
                {data.value_desc}
              </Typography>
              {data.key_points.map((points, i) => {
                return (
                  <div
                    className="key-point"
                    key={i}
                    style={{ display: "flex", marginTop: "10px" }}
                  >
                    <DoneIcon style={{ color: "rgba(61, 213, 200, 1)" }} />
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{
                        marginLeft: "10px",
                        color: "#595959",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {points}
                    </Typography>
                  </div>
                );
              })}
              <div className="buttons">
                <Button
                  onClick={() => {
                    data.embedded_tool_link && data.embedded_tool_link !== null
                      ? setIframeModalOpenAndSetLink(
                          data.tool_link,
                          data.tool_name
                        )
                      : navigateToTool(data.tool_link, data.tool_name);
                  }}
                  variant="contained"
                  size="large"
                  className="view-tool-button"
                  style={{ fontFamily: "Montserrat" }}
                >
                  View Tool
                </Button>
              </div>
            </div>
            <div className="right">
              <div className="video-responsive">
                <iframe
                  width="653"
                  height="370"
                  src={`https://www.youtube.com/embed/${data.demo_link}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title=""
                />
              </div>
            </div>
          </div>
          {data.testimomials.length > 0 && (
            <div className="testimonials" style={{ marginTop: "20px" }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  color: "#00A5A5",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                OUR CLIENTS
              </Typography>
              <div className="testimonials-nvigation">
                {data.testimomials.map((testimonial, j) => {
                  console.log(data.testimomials.length);
                  return (
                    <div
                      className={
                        activeTestimonialIndex === j
                          ? "testimonial-link active-testimonial"
                          : "testimonial-link"
                      }
                      onClick={() => {
                        setActiveTestimonialIndex(j);
                      }}
                    >
                      <img src={testimonial.image} />
                    </div>
                  );
                })}
              </div>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  color: "#00A5A5",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                TESTIMONIALS
              </Typography>
              <dev className="test" style={{ display: "flex" }}>
                <div className="right-test">
                  <FormatQuoteIcon
                    fontSize="large"
                    style={{ transform: "rotate(180deg)", color: "#00A5A5" }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ fontWeight: 600, fontFamily: "Montserrat" }}
                  >
                    {data.testimomials[activeTestimonialIndex] &&
                      data.testimomials[activeTestimonialIndex]
                        .testimomial_header}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{
                      fontWeight: 400,
                      color: "rgba(45, 42, 43, 0.8)",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {data.testimomials[activeTestimonialIndex] &&
                      data.testimomials[activeTestimonialIndex]
                        .testimomial_desc}
                  </Typography>
                  <Typography
                    variant="h6"
                    display="block"
                    gutterBottom
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                    }}
                  >
                    -{" "}
                    {data.testimomials[activeTestimonialIndex] &&
                      data.testimomials[activeTestimonialIndex].designation}
                  </Typography>
                </div>
              </dev>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <div
                  className="prev-arrow"
                  onClick={
                    activeTestimonialIndex === 0
                      ? null
                      : () =>
                          setActiveTestimonialIndex(activeTestimonialIndex - 1)
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    name="arrow left"
                    size="large"
                    style={{
                      cursor: "pointer",
                      color: activeTestimonialIndex === 0 ? "grey" : "black",
                    }}
                  />
                </div>
                <div
                  className="prev-arrow"
                  onClick={
                    activeTestimonialIndex === data.testimomials.length - 1
                      ? null
                      : () =>
                          setActiveTestimonialIndex(activeTestimonialIndex + 1)
                  }
                  style={{
                    marginLeft: "40px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    name="arrow right"
                    size="large"
                    style={{
                      color:
                        activeTestimonialIndex === data.testimomials.length - 1
                          ? "grey"
                          : "black",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="features" style={{ marginTop: "20px" }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                color: "#00A5A5",
                fontSize: "14px",
                fontFamily: "Montserrat",
              }}
            >
              FEATURES
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 600, fontFamily: "Montserrat" }}
            >
              {data.feature_heading}
            </Typography>
            <div className="feature_list">
              {data.features.map((feature, j) => {
                return (
                  <div className="feature" key={j}>
                    <div className="feature-image">
                      <img
                        style={{ width: "35px" }}
                        src={IMAGECONFIG[`FEATURELOGO${j + 1}`]}
                      />
                    </div>
                    <div className="feature-value">
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        style={{ fontWeight: 800, fontFamily: "Montserrat" }}
                      >
                        {feature.value ? feature.value : null}
                      </Typography>
                      {feature.value_desc
                        ? feature.value_desc.map((desc, k) => {
                            return (
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                key={k}
                                style={{ fontFamily: "Montserrat" }}
                              >
                                {desc}
                              </Typography>
                            );
                          })
                        : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {data.properties.length > 0 && (
            <div className="properties" style={{ marginTop: "20px" }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  color: "#00A5A5",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                PROPERTIES
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                style={{ fontWeight: 600, fontFamily: "Montserrat" }}
              >
                Highlighted Use Case
              </Typography>
              <div className="glider">
                {data.properties.map((property, j) => {
                  return (
                    <dev
                      className="property glides"
                      style={{
                        display: "flex",
                        transform: `translateX(${value}%)`,
                        minWidth: "100%",
                        justifyContent: "space-between",
                      }}
                      key={j}
                    >
                      <div className="left-property" style={{ width: "30%" }}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{ fontWeight: 600, fontFamily: "Montserrat" }}
                        >
                          {property.heading}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            fontWeight: 400,
                            color: "rgba(45, 42, 43, 0.8)",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {property.description}
                        </Typography>
                      </div>
                      <div
                        className="right-property"
                        style={{
                          width: "70%",
                          display: "flex",
                          justifyContent: "right",
                          marginRight: "20px",
                        }}
                      >
                        <img
                          src={property.image}
                          style={{ height: "300px", width: "60vw" }}
                        />
                      </div>
                    </dev>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "15px",
                  padding: "0 10px 0 0",
                }}
              >
                <ArrowRightAltIcon
                  id="moveBehind"
                  onClick={value === 0 ? null : moveAhead}
                  style={{
                    color: value === 0 ? "grey" : "black",
                    fontSize: "30px",
                  }}
                />
                <ArrowRightAltIcon
                  id="moveAhead"
                  onClick={
                    value === -100 * (data.properties.length - 1)
                      ? null
                      : moveBehind
                  }
                  style={{
                    color:
                      value === -100 * (data.properties.length - 1)
                        ? "grey"
                        : "black",
                    fontSize: "30px",
                  }}
                />
              </div>
            </div>
          )}
          <div className="view-more-tools-tab">
            <Typography
              variant="h6"
              gutterBottom
              style={{
                color: "#00A5A5",
                fontSize: "14px",
                fontFamily: "Montserrat",
              }}
            >
              VIEW MORE
            </Typography>
            <Grid container spacing={1} style={{ marginTop: "10px" }}>
              {data.extra_tools.map((card, l) => {
                return (
                  <Grid item xs={3} key={l}>
                    <Card className="card-tool">
                      <CardContent className="card-content">
                        <div className="top-bottom-card-content">
                          <div className="top-card-content">
                            <div className="logo-and-name">
                              <div
                                className="promo-logo"
                                style={{
                                  background: CONFIG.TOOL_ICON_BACKGROUND[l],
                                }}
                              >
                                {card.logoLink ? (
                                  <img
                                    src={card.logoLink}
                                    alt={card.name}
                                    className="promo-icons"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <h6
                                onClick={() =>
                                  setToolDataAndNavigate(card.projectLink)
                                }
                                className="promo-name"
                                style={{ cursor: "pointer" }}
                              >
                                {card.name}
                              </h6>
                            </div>
                            <p className="promo-oneLiner">{card.description}</p>
                          </div>
                          <div className="top-card-content">
                            <div className="update-and-users">
                              <Typography
                                variant="caption"
                                className="text-val"
                                gutterBottom
                                display="block"
                                style={{ color: "#595959" }}
                              >
                                Updated 1wk ago
                              </Typography>
                              <div className="users">
                                <PeopleOutlineOutlinedIcon
                                  style={{ fontSize: "1.3em" }}
                                />
                                <Typography
                                  variant="caption"
                                  className="text-val"
                                  gutterBottom
                                  display="block"
                                  style={{
                                    color: "#595959",
                                    marginLeft: "5px",
                                  }}
                                >
                                  22
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "-20px" }}>
          {/* <div
            className="action-bar"
            style={{
              display: "flex",
              background: "#2D2A2B",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <Icon
              onClick={() => window.open(`${iframeLink}`)}
              size="large"
              style={{ color: "white", cursor: "pointer" }}
              name="window restore outline"
            />
            <Icon
              size="large"
              style={{ color: "white", cursor: "pointer" }}
              name="close"
              onClick={() => setIframeModalOpen(false)}
            />
          </div> */}
          <div style={{ position: "relative", width: "100%", height: "700px" }}>
            {isLoading ? (
              <div className="loader-data">
                <div className="spinner">
                  {[...Array(5)].map((_, index) => (
                    <div key={index}></div>
                  ))}
                </div>
                <div className="loading-message">Loading data...</div>
              </div>
            ):""}
            <iframe
              ref={iframeRef}
              src={data.embedded_tool_link}
              width="100%"
              height="700"
              frameBorder="0"
              // allow="accelerometer; autoplay; camera; clipboard-write; encrypted-media; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; payment; picture-in-picture; usb; vr"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              onLoad={handleIframeLoad}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ToolsHomePage;
