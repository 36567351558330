import React, { useEffect } from "react";
import { postData } from "../utils/dataServices";
import { getPermission, setPermission } from "../utils/services";
import { setComingFrom } from "../utils/services";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../utils/getCookie";
import { Typography, Box, CircularProgress } from "@mui/material";

function Update() {
  const navigate = useNavigate();
  //just for testing
  localStorage.setItem("hubble_accessToken", getCookie("hubble_access_token"));
  localStorage.setItem("hubble_username", getCookie("hubble_username"));
  localStorage.setItem("hubble_expiry", getCookie("hubble_expiry"));
  localStorage.setItem("hubble_first_name", getCookie("hubble_first_name"));
  useEffect(() => {
    let payload = {
      name: getCookie("hubble_first_name"),
      username: getCookie("hubble_username"),
      access_token: getCookie("hubble_access_token"),
      expiresOn: getCookie("hubble_expiry"),
    };
    postData(payload).then((res) => {
      console.log("save_token_result", res);
      navigate("/navigation/my-favourites");
    });
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
      <Box mt={2}>
        <Typography variant="h6" component="div">
          Authentication in progress...
        </Typography>
      </Box>
    </Box>
  );
}

export default Update;
