import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import configureStore from './redux/store';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'


const instance = createInstance({
  urlBase: "http://localhost/matomo_analytics/",
  siteId: 2,
  userId:"user"
});

const store = configureStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <MatomoProvider value={instance} >
      <App />
      </MatomoProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
