export const IMAGECONFIG = {
        PIN: require('../Images/pin.svg'),
        UNPIN: require('../Images/unpin.svg'),
        LOGO:require('../Images/Logo.png'),
        LOGOWITHOUTTEXT:require('../Images/Group 513674.png'),
        HUBBLELOGO:require('../Images/MicrosoftTeams-image.png'),
        TOOL_HOME_PAGE_TOP_SECTION_RIGTH_IMAGE:require('../Images/Screenshot 2022-08-16 at 2.24 1.png'),
        VIEW_TOOL_TAB:require('../Images/Group 7903.png'),
        LOGINSCREENTOOLMOCKUP:require("../Images/Screen mockup (REPLACE FILL).png"),
        TRUSTED_CLIENT_1:require('../Images/image 58.webp'),
        TRUSTED_CLIENT_2:require('../Images/image 59.webp'),
        TRUSTED_CLIENT_3:require('../Images/Kellanova_logo.svg 3.png'),
        TRUSTED_CLIENT_4:require('../Images/MicrosoftTeams-image (5).png'),
        TRUSTED_CLIENT_5:require('../Images/image 64.png'),
        SAMPLE_USER_1:require('../Images/Ellipse 3.png'),
        SAMPLE_USER_2:require('../Images/Ellipse 4.png'),
        PRICINGVECTOR:require('../Images/PricingVector.png'),
        ASSORTMENTVECTOR:require('../Images/AssortmentVector.png'),
        TRADEVECTOR:require("../Images/TradeVector.png"),
        INTEGERATEDVECTOR:require("../Images/IntegeratedVector.png"),
        PROMOTIONVECTOR:require('../Images/PromotionVector.png'),
        VALUEPNG:require('../Images/value (1) 1.png'),
        VOLUMEPNG:require('../Images/boxes 1.png'),
        TRANSACTIONPNG:require('../Images/transaction (1) 1.png'),
        PROFITPNG:require('../Images/bars 1.png'),
        PRICINGPNG:require('../Images/price-tag 3.png'),
        LINECHARTPOSITIVE:require('../Images/Vector 1578.png'),
        LINECHARTNEGETIVE:require('../Images/Vector 1580.png'),
        BEAGLEICON:require('../Images/Group.png'),
        TEAMSICON:require('../Images/TeamsIcon.png'),
        WARNINGLOGO:require('../Images/Warning.png'),
        STABLELOGO:require('../Images/Stable.png'),
        FEATURELOGO1:require('../Images/1.png'),
        FEATURELOGO2:require('../Images/2.png'),
        FEATURELOGO3:require('../Images/3.png'),
        FEATURELOGO4:require('../Images/4.png'),
        FEATURELOGO5:require('../Images/5.png'),
        FEATURELOGO6:require('../Images/6.png'),
        FEATURELOGO7:require('../Images/7.png'),
        FEATURELOGO8:require('../Images/8.png'),
        FEATURELOGO9:require('../Images/9.png'),
        FEATURELOGO10:require('../Images/10.png'),
        LOGOUT:require('../Images/Logout.png'),
        TOURMODALLOGO:require('../Images/RGM_Hubble_logo.gif'),
        POIIMAGE:require('../Images/MicrosoftTeams-image (58) 1.png'),
        TOUREXITLOGO:require('../Images/Rectangle 4152910.png'),
        ALLTOOLSLOGO:require('../Images/ALL TOOLS.png'),
        BEAGLELOGO:require('../Images/beagleLogo.png'),
        DASHBOARDLOGO:require('../Images/dashboard (1).png'),
        KPILOGO:require('../Images/key-performance-indicator (1).png'),
        RGMLOGO:require('../Images/RGM.png'),
        LINKEDINLOGO:require('../Images/linkedInLogo.png'),
        ASKBEAGLE:require("../Images/ask-beagle.svg")
}

