let allowed = false
let comingFrom = ''
let user = ''
export const setPermission=(value)=>{
    allowed = value
  }

 export  const getPermission=()=>{
    return allowed
  }

  export const setComingFrom=(value)=>{
    comingFrom = value
  }

  export const getComingFrom=()=>{
    return comingFrom
  }

  export const setUser=(user)=>{
    user = user
  }

  export const getUser=()=>{
    return user
  }