
export const ToolAction ={
    SET_TOOL_DATA:"SET_TOOL_DATA",
    TOGGLE_TOOL:"TOGGLE_TOOL"
}

export const setToolData = (tool_data) =>{
  return {
      type: ToolAction.SET_TOOL_DATA,
      payload: { tool_data: tool_data },
    }
  }

  export const setTourToggle=() => {
    return{
      type:ToolAction.TOGGLE_TOOL,
      payload:true
    }
  }